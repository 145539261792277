import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Stop aux feux tricolores...`}</h1>
    <p>{`D'innombrables échelles vert, orange, rouge peuplent les tableaux de bord de toutes origines ?
La symbolique des couleurs du feu de croisement profite de l'universalité du code de la route: vert=ok, orange=alerte, rouge=problème. Tellement utilisé que l'on n'y pense même plus.`}</p>
    <p>{`Une minute, ... Est ce une bonne visualisation ?
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAADZ0lEQVQ4y3WUbVCUVRTH96Pj1z42ETXgQqsgE29BIdGLYW6SDoyyjstOqbC2LmywoBkxYjUDVjaYRTTTFOoSCCIkLTNh46iDCPgCNBrxtgu0b8DqwhLZsL/us4DIS3fmznPvc8793XPm/O+RIYbP58P/lebsrH/9r2eS3kuXqS07xbdFR6koKqaxvIL+y1f5Z2ychXOPzs6vZf4f0uYx2JQ4YK1vojh5GzufDiZzYzT7w6PYFbiOkpQ0Bqvr8Yz8NQeaXQqVLYc9FJFVaPUY4hLRRseTFfMixoQk3o9PJDNK7KPiMMS/TPneA/ztHJuHzj6CynyPpTw94cZubqHlk+O8K0Uk34AuVIHpeQU/hsvJkj/HzmCFsEX6fWyNzUzZ7KukLMaM+z4jDWYGKqtxXPiF1s9PopKHoXsqgKsbAmgJfRKtWEvA1i++8vv0V/6E9VwDXrtjMeUF4ETX7wyeqeF+z12mBi1Md3bxjSjE7uQ3yY8IwxgZgTplO1/u1TJ1vROvZRjPn/1YaxtxtrYzT1wEjt/qwn7pCt5RG33NLdw5V0+pLpv8TC3vvarkiKGAg9u2U1ZYRPdFM9a2DiZu9/Dg7h84r7WtBEoRSrc9dI1TUXCE9IAgDiYrMe5S8VlKAl8b1LwTGk6eWoNuRxo/lBxnWmQycadndaBbpDpwuppRIRdnbx/FKjWq6AQ+zdhKh1FBd9lmDOsD0Ch3UJKdg/1eL+7O21iEhFxtnaunPHa9w2+c7LmHo/0mOVtSMKQpOa18gqZjyeyPi+LQPq3fNikC6Pv+LENVdYy13/JLbxmwm+G6n/EKwc6M2Bhv/o0TogCql5JQB4ag27yVfao9nNLl4LlxE7eASNENmepwiUBWAKcdLiw1F7BUnac69xAn0tXoX9iENiKW3LcTyd4iRB0Rgz42QdgyhM9hrJK/gE6Kii+RzQJUEqldiPWsPo+0Z+VkhGxE81YwxvIYck9Gon4jCLU8nNRn1nFG+NjON/FgYGipsBef3iLU1fQrdfkfsidEQVZeIDnmMPQX15OpDyQ9SE5tQSHORjMeUeXlTUK2pFssNAcBdQro4aTXSI1bg+boWjRFa0mNXcMHr7yOo2F12IqU56BzEc+Ih99aacJUeoz67wrF/Iiq0o+5YarBOzyK73/a138bO+zooOxmoQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "feutrs",
          "title": "feutrs",
          "src": "/static/5da112efd8c4f18766117c7ccd4e092b/13ae7/feutrs.png",
          "srcSet": ["/static/5da112efd8c4f18766117c7ccd4e092b/222b7/feutrs.png 163w", "/static/5da112efd8c4f18766117c7ccd4e092b/ff46a/feutrs.png 325w", "/static/5da112efd8c4f18766117c7ccd4e092b/13ae7/feutrs.png 350w"],
          "sizes": "(max-width: 350px) 100vw, 350px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`La route`}</h3>
    <p>{`Revenons à l'origine. Le premier feu de croisement (bicolore) est installé à `}<a parentName="p" {...{
        "href": "http://fr.wikipedia.org/wiki/Feu_de_circulation"
      }}>{`Salt  Lake City en 1912`}</a>{`. Il fut progressivement enrichi d'états supplémentaires: orange, clignotements, combinaisons, ... L'objectif global restant d'organiser le partage harmonieux d'une ressource exclusive: `}<em parentName="p">{`le carrefour`}</em>{`.`}</p>
    <ul>
      <li parentName="ul">{`vert     = `}<strong parentName="li">{`passez, l'autre voie est rouge`}</strong>{` `}</li>
      <li parentName="ul">{`rouge 	= `}<strong parentName="li">{`arrêtez vous, l'autre voie est vert`}</strong></li>
      <li parentName="ul">{`orange 	= `}<strong parentName="li">{`attention, le feu va passer au rouge`}</strong></li>
    </ul>
    <h3>{`Le rail`}</h3>
    <p>{`Un autre exemple vénérable de partage d'une section critique est le passage à niveau. Vu de loin il s'agit du même problème que la route: l'accès raisonné à cette petite portion d'espace à cheval entre le bitume et les rails. `}</p>
    <p>{`Sauf qu'il y a une différence majeure (autre que la priorité du train qui impose le rouge à la route): La voiture peut passer sans danger 99,9% du temps, il serait donc stupide (et écologiquement irresponsable) d'allumer un feu vert tout ce temps. Quand il existe, le signal lumineux (rouge clignotant) du passage à niveau se déclenche quelques instants avant le passage du train et ... s'éteint après.`}</p>
    <p>{`C'est vrai après tout, le vert n'est pas nécessaire, on pourrait aussi au carrefour routier n'utiliser que le rouge: `}<strong parentName="p">{`le feu mono colore`}</strong>{`... `}<strong parentName="p">{`Rouge = stop`}</strong>{`, `}<strong parentName="p">{`Rien = passez`}</strong>{`. Le vert n'apporte aucune information supplémentaire.`}</p>
    <p>{`A y réfléchir de plus près, le feu vert offre un discret bénéfice qui, à lui seul, rend souhaitable de le conserver à nos carrefours: la redondance de sécurité - en l'absence de feu vert une ampoule rouge `}<strong parentName="p">{`en panne`}</strong>{` serait catastrophique...`}</p>
    <h3>{`Le tableau de bord`}</h3>
    <p>{`Quel est donc le rapport entre les indicateurs de suivi, de pilotage d'une activité et ... un feu tricolore? Pas grand chose !!`}</p>
    <p>{`Ce qui ne va pas dans le feu tricolore pour les tableaux de bord, c'est `}<strong parentName="p">{`le vert`}</strong>{`. Il est:`}</p>
    <ul>
      <li parentName="ul">{`redondant: l'information utile est la présence d'alertes ou de problèmes.  `}<strong parentName="li">{`Je vois bien que ce n'est pas rouge !`}</strong></li>
      <li parentName="ul">{`nuisible: de nombreux indicateurs sont simultanément présents sur le tableau de bord. `}<strong parentName="li">{`Le rouge est dilué dans le vert !`}</strong>{` `}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.74233128834356%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdqAsB//xAAYEAACAwAAAAAAAAAAAAAAAAAAEQECMf/aAAgBAQABBQJSq4f/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAZEAADAAMAAAAAAAAAAAAAAAAAASERUWH/2gAIAQEAAT8hSquB3wy7P//aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQEBAQAAAAAAAAAAAAABESEAMUH/2gAIAQEAAT8QlmIMa7yoSWsMeA31v17/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "feuverttrs",
          "title": "feuverttrs",
          "src": "/static/bb14d2ba0ef46fd0ea35397322662dc0/6aca1/feuverttrs.jpg",
          "srcSet": ["/static/bb14d2ba0ef46fd0ea35397322662dc0/d2f63/feuverttrs.jpg 163w", "/static/bb14d2ba0ef46fd0ea35397322662dc0/c989d/feuverttrs.jpg 325w", "/static/bb14d2ba0ef46fd0ea35397322662dc0/6aca1/feuverttrs.jpg 650w", "/static/bb14d2ba0ef46fd0ea35397322662dc0/7c09c/feuverttrs.jpg 975w", "/static/bb14d2ba0ef46fd0ea35397322662dc0/01ab0/feuverttrs.jpg 1300w", "/static/bb14d2ba0ef46fd0ea35397322662dc0/ce2f1/feuverttrs.jpg 1732w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`On sait aujourd'hui que le cerveau est ultra efficace à détecter ce qui se détache de la `}<strong parentName="p">{`moyenne`}</strong>{` ambiante. C'est vrai pour les bruits, les odeurs et bien sûr la vue. `}</p>
    <p>{`Les concepteurs de panneaux de contrôle de sécurité ont un dicton qui dit la même chose: `}<strong parentName="p">{`Quand tout clignote, rien ne clignote`}</strong>{`.`}</p>
    <p>{`Sur un tableau de bord `}<strong parentName="p">{`le vert le plus efficace c'est ... le blanc`}</strong>{`... `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      